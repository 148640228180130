<template>
  <div>
    <el-drawer class="show-drawer-box"
               title="支付订单"
               :with-header="false"
               :visible.sync="dialogDrawer"
               :before-close="handleClose"
               :show-close="true"
               :direction="'btt'">
      <div class="show-drawer-item">
        <div class="pay-title">
          <img src="@/assets/images/icon/order.png" alt="" />
          <h3>支付订单</h3>
        </div>
        <div class="pay-h2">{{ title }}</div>
        <div class="pay-check">
          <div v-if="!isWeChatBrowser()">
            <img alt="" src="@/assets/images/icon/zfb-pay2.png">
            <span>支付宝支付</span>
            <el-radio class="pay-radio" v-model="payMethod" label="1">{{""}}</el-radio>
          </div>
          <div>
            <img alt="" src="@/assets/images/icon/wx-pay2.png">
            <span>微信支付</span>
            <el-radio class="pay-radio" v-model="payMethod" label="2">{{""}}</el-radio>
          </div>
        </div>
        <div class="pay-btn">
          <div class="pay-txt">
            总金额：
            <span>{{orderDetail.price}}元</span>
            <i>{{orderDetail.showPrice}}元</i>
          </div>
          <el-button type="primary" size="small" @click="openPay">确认支付</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data(){
    return {
      dialogDrawer: false,
      activeDrawerName: '1',
      orderDetail: '',
      title: '',
      payMethod: '1'
    }
  },
  created() {},
  methods: {
    showDrawer(param){
      // console.log(param)
      this.dialogDrawer = param.show
      this.title = param.title
      this.orderDetail = param.info
    },
    // 移动端 调取支付
    openPay(){
      let _this = this
      // let order = localStorage.getItem('orderId')
      // console.log(order)
      if (this.isWeChatBrowser()) {
        window.location.href = `/prod-admin-api/paper/api/public/pay/${_this.orderDetail.id}`
      }  else {
        if (_this.payMethod == 1) {
          // 支付宝支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.orderDetail.id}/1`
        } else {
          // 微信支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.orderDetail.id}/2`
        }
      }
      let info = {
        showState: false,
        payState: true
      }
      this.$emit("downloadOff", info)
      this.dialogDrawer = false
    },
    handleClose() {
      this.$confirm('确认关闭？',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        let info = {
          showState: false,
          payState: false
        }
        this.$emit("downloadOff", info)
        this.dialogDrawer = false
      }).catch(() => {
      });
    },
    // 判断是否是微信浏览器
    isWeChatBrowser() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isWx = /micromessenger/.test(userAgent)
      if (isWx) {
        this.payMethod = '2'
      }
      return isWx;
    }
  }
}
</script>
<style scoped lang="less">
.show-drawer-box{
  /deep/ .el-drawer__header{
    margin-bottom: 10px !important;
  }
  /deep/ .el-drawer{
    height: 50% !important;
    border-radius: 15px 15px 0 0;
  }
  .show-drawer-item{
    position: relative;
    width: 94%;
    margin: 0 auto;
    padding-top: 20px;
    height: 100%;
    .pay-title{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      h3{
        font-size: 16px;
        font-weight: bold;
      }
    }
    .pay-h2{
      padding: 20px 0;
      text-align: center;
    }
    .pay-check{
      width: 90%;
      margin: 10px auto 0 auto;
      background-color: #ffffff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #e4e5e7;
      >div{
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        span{
          font-size: 12px;
        }
        .pay-radio{
          margin-left: auto;
        }
      }
      >div:last-child{
        border-top: 1px solid #e4e5e7;
      }
    }
    .pay-btn{
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 30px;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .pay-txt{
        font-size: 12px;
        color: #3c3c3c;
        span{
          margin-right: 5px;
          font-size: 18px;
          font-weight: bold;
          color: #fc5151;
        }
        i{
          font-size: 12px;
          color: #9c9c9c;
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>
